<template>
  <div style="padding: 15px;" class="uu-teaching-preview">
    <NCard
      size="small"
      :segmented="{ content: 'hard' }"
      :title="resourceTitle"
    >
      <BriefInfo
        :brief-info="briefInfo"
      />
      <LabelInfo
        :label-list="labelList"
      />
      <div style="margin: 0 -16px; overflow: hidden;">
        <ModuleContent
          :module-data="moduleData"
          hide-resource-list
          @resource-title-change="resourceTitle = $event"
          @label-list-change="labelList = $event"
          @brief-info-change="briefInfo = $event"
        />
      </div>
    </NCard>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useRoute } from 'vue-router';

  import ModuleContent from '@/views/material/preview/components/ModuleContent.vue';
  import BriefInfo from './components/BriefInfo.vue';
  import LabelInfo from './components/LabelInfo.vue';

  import { resourceEnum } from '@/enumerators/resource-types-map.js';

  import { getTeachingDetail } from '@/api/teaching.js';

  const route = useRoute();

  const teachingId = route.query.id;
  const moduleData = {
    title: '',
    type_source_list: [
      {
        data_type: resourceEnum.TEACHING_RESOURCE_TYPE,
        source_id: [teachingId],
        title: ''
      }
    ]
  };

  const resourceTitle = ref('');
  const briefInfo = ref({});
  const labelList = ref([]);
</script>